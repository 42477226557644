<template>

  <v-card
      outlined class="ma-0 pa-1" elevation="2">
    <v-row class="d-flex justify-center">


      <v-col v-for="(value, name, index) in showLead(lead)" :key="index">

        {{ name }}

        <sibling-menu v-if="name=='sibling'" :name="lead.sibling" :phone="lead.phone"/>

        <phone-menu v-else-if="name=='phone'" :lead="lead" />

        <!--        <div v-else-if="name=='email'">-->
        <!--          <v-btn x-small v-for="address in lead.email" :key="address">{{ address }}</v-btn>-->
        <!--        </div>-->
        <email-menu x-small v-else-if="name=='email'" :lead="lead"/>

        <IPBadge :ip="value" v-else-if="name=='arecord'"/>

        <date-display :date="value" v-else-if="name.endsWith('Date')"
                      date-format="DD MMM YYYY"
        />

        <div v-else> {{ value }}</div>
      </v-col>


    </v-row>


    <v-row class="d-flex justify-center">
      <conversation-tab
          :conversation="getConversation(lead.id)"
          :lead="lead"
          :show-form="true"
          :showSiblingMenu="true"
          @collapse="$emit('collapse')"
      />

    </v-row>

  </v-card>


</template>

<script>
import SiblingMenu from "@/components/SiblingMenu.vue";
import DateDisplay from "@/components/DateDisplay";
import IPBadge from "@/components/IPBadge.vue";
import PhoneMenu from "@/components/PhoneMenu.vue";
import EmailMenu from "@/components/EmailMenu.vue";
import ConversationTab from "@/components/conversations/ConversationTab.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "LeadCard",
  components: {
    SiblingMenu,
    DateDisplay,
    IPBadge,
    EmailMenu,
    PhoneMenu,
    ConversationTab,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      hidden: true,
    }
  },
  computed: {
    ...mapGetters("conversations", ["getConversationByLeadId"]),

  },

  methods: {
    ...mapActions("conversations", [
      "placeCallAction"
    ]),

    getConversation(id) {
      return this.getConversationByLeadId(id)
    },
    showLead() {
      let display = (({
                        id, domain, fromNumber, builtWithCatNumber, blocked, validated, validatedMobile,
                        carrierName, mobileNetworkCode, status, carrierType, mobile, sentDate, textable,
                        templateName, emailSender, dropDate, whoisCheckedDate, type, ...o
                      }) => o)(this.lead)
      return display;
    },
    callLead() {
      const payload = {
        data: {
          'Calling to': this.lead.phone,
        },
        color: "blue",
        timeout: 10000,
      };
      this.$store.dispatch("snackbar/setSnackbar", payload);
      this.placeCallAction(this.lead.phone);
    }
  },


}
</script>

<style scoped>

</style>
