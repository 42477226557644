<template>
  <v-container
      class="elevation-5"
      fluid v-if="conversation" >
    <v-row v-if="conversation.messages">
      <v-col cols="4">
        <sibling-menu v-if="showSiblingMenu" :name="conversation.sibling" :phone="conversation.phone"/>
      </v-col>
      <v-col cols="4">
        <data-card :data="conversation" :caption="conversation.id"></data-card>
      </v-col>
      <v-col cols="4" order="last">
        <v-select
            dense
            :value="this.conversation.stage"
            :items="conversationStages"
            @change="updateStage"
            hide-details
            class="mt-0"
        />
      </v-col>

    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <message-list :conversation="conversation" @collapse="$emit('collapse')"/>
        <sibling-menu :name="conversation.sibling" :phone="conversation.phone"/>
        <message-form
            :conversation="conversation"
        />
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import {mapMutations, mapState, mapActions, mapGetters} from "vuex";
import MessageList from "@/components/message/MessageList";
import MessageForm from "@/components/message/MessageForm";
import SiblingMenu from "@/components/SiblingMenu";
import EvaluationMenu from "@/components/EvaluationMenu";
import DomainCard from "@/components/domains/DomainCard";
import DataCard from "@/components/domains/DataCard";
import PhoneMenu from "@/components/PhoneMenu.vue";
import DomainMenu from "@/components/DomainMenu.vue";

export default {
  name: "ConversationTab",
  components: {
    SiblingMenu, DataCard,
    MessageList, MessageForm
  },

  props: ["conversation", "lead", "showSiblingMenu"],


  computed: {
    ...mapState("conversations", ["conversationStages"]),
    ...mapGetters("conversations", ["getConversationByPhone"]),

  },


  methods: {
    ...mapMutations({
      removeMessage: "messages/REMOVE_MESSAGE"
    }),
    ...mapActions("conversations", [
      "placeCallAction",
      "updateConversationAction",
    ]),
    ...mapActions("messages", ["updateMessageStageAction"]),

    updateStage(value) {

      this.updateConversationAction({id: this.conversation.id, stage: value});
      this.updateMessageStageAction({domainName: this.conversation.domain, stage: value});
    },
  },


};
</script>

<style scoped>

</style>
